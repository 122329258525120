import React from "react";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { firebase } from "~/src/api";

import {
  Banner,
  Dialog,
  FormLayout,
  Button,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  contactName: yup.string().required("Name is required"),
  contactEmail: yup.string().email("Must be a valid email address")
    .required("Email address is required"),
  contactMessage: yup.string().required("Message is required"),
});

function ContactDialog({
  user, teacher, site, handleCloseDialog, dialogOpen,
}) {
  const [success, setSuccess] = React.useState(null);

  return (
    <Dialog onClose={handleCloseDialog} id="contact-dialog" open={dialogOpen} title={`Contact ${teacher.firstname}`}>
      <Formik
        initialValues={{
          contactName: "",
          contactEmail: "",
          contactMessage: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const { contactName, contactEmail, contactMessage } = values;

          const emailRef = firebase.emails().doc();
          await emailRef.set({
            to: {
              authID: user.authID,
              email: user.email,
              firstname: teacher.firstname,
              lastname: teacher.lastname,
            },
            from: {
              name: contactName,
              email: contactEmail,
            },
            domain: site.domain,
            message: contactMessage,
            timestamp: firebase.fieldValue.serverTimestamp(),
          });

          setSuccess("Message sent!");
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormLayout>
              {success ? (
                <div>
                  <FormLayout>
                    <Banner status="success">{success}</Banner>

                    <FormLayout.Footer>
                      <Button color="primary" onClick={handleCloseDialog}>Done</Button>
                    </FormLayout.Footer>
                  </FormLayout>
                </div>
              ) : (
                <div>
                  <FormLayout>
                    <Field
                      label="Name"
                      name="contactName"
                      component={TextField}
                      autoFocus
                    />
                    <Field
                      label="Email Address"
                      name="contactEmail"
                      component={TextField}
                      type="email"
                    />
                    <Field
                      label="Message"
                      name="contactMessage"
                      component={TextField}
                      multiline
                    />
                    <FormLayout.Footer>
                      <Button onClick={handleCloseDialog}>Cancel</Button>

                      <Button
                        color="primary"
                        loading={isSubmitting}
                        type="submit"
                      >
                        Send Message
                      </Button>
                    </FormLayout.Footer>
                  </FormLayout>
                </div>
              )}
            </FormLayout>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default ContactDialog;
