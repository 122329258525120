import React from "react";
import { Divider as MuiDivider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: [[theme.spacing(6), 0]],
  },
}));

function Divider() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MuiDivider />
    </div>
  );
}

export default Divider;
