import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/CheckCircleOutlined";
import SAFETY_CHECKS from "~/src/constants/safetyChecks";

import { getMonthAndYear, getMonthAndYearRange } from "~/src/components/shared";

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    "& $listItem": {
      flexGrow: 1,
      flexShrink: 0,
      minWidth: 320,
      width: "50%",
    },
  },
  listItem: {
    "& + $listItem": {
      paddingTop: theme.spacing(2),
    },
  },
  listItemIcon: {
    marginTop: theme.spacing(0.5),
  },
}));

function Longform({ teacher, wrap, inheritColor }) {
  const classes = useStyles();

  const { safetyChecks } = teacher;

  const renderListItem = (safetyCheck, safetyCheckValues) => {
    const safetyCheckMatched = SAFETY_CHECKS.find((SAFETY_CHECK) => SAFETY_CHECK.name === safetyCheck);

    return safetyCheckValues && safetyCheckValues.active ? (
      <ListItem className={classes.listItem} key={safetyCheckMatched.name}>
        <ListItemIcon className={classes.listItemIcon} style={{ color: inheritColor ? "inherit" : undefined }}>
          {safetyCheckMatched.icon}
        </ListItemIcon>
        <ListItemText
          primary={safetyCheckMatched.label}
          secondary={safetyCheckValues.expiryDateYear ? `Valid until ${getMonthAndYear({
            dateYear: safetyCheckValues.expiryDateYear,
            dateMonth: safetyCheckValues.expiryDateMonth,
          })}` : safetyCheckMatched.detail}
          primaryTypographyProps={{
            variant: "body2",
            color: inheritColor ? "inherit" : undefined,
          }}
          secondaryTypographyProps={{
            variant: "body1",
            color: inheritColor ? "inherit" : undefined,
          }}
        />
      </ListItem>
    ) : null;
  };

  return (
    <List className={wrap ? classes.wrap : undefined}>
      {teacher.qualifications.map((qualification, index) => (
        <ListItem className={classes.listItem} key={index}>
          <ListItemIcon className={classes.listItemIcon} style={{ color: inheritColor ? "inherit" : undefined }}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText
            primary={qualification.name}
            secondary={getMonthAndYearRange({ ...qualification })}
            primaryTypographyProps={{
              variant: "body2",
              color: inheritColor ? "inherit" : undefined,
            }}
            secondaryTypographyProps={{
              variant: "body1",
              color: inheritColor ? "inherit" : undefined,
            }}
          />
        </ListItem>
      ))}
      {safetyChecks && renderListItem("insurance", safetyChecks.insurance)}
      {safetyChecks && renderListItem("firstAid", safetyChecks.firstAid)}
      {safetyChecks && renderListItem("cpr", safetyChecks.cpr)}
    </List>
  );
}

export default Longform;
