import { createMuiTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  themeOverride,
} from "~/src/sites";

import SITE_FONTS from "~/src/constants/siteFonts";

function CustomiseThemeBase({ site }) {
  const theme = createMuiTheme(themeOverride);
  const contrastColor = theme.palette.getContrastText(site.customisation.colorBackground);
  const colorBlack = theme.palette.getContrastText("#FFF");

  theme.fontBody = SITE_FONTS.find((font) => font.value === site.customisation.fontBody);
  theme.fontHeader = SITE_FONTS.find((font) => font.value === site.customisation.fontHeader);

  theme.shadows[0] = `0 0 0 1px ${fade(contrastColor, 0.15)}`;
  theme.shadows[1] = `0 1px 1px ${fade(contrastColor, 0.15)}`;
  theme.shadows[2] = `0 0 0 1px ${fade(contrastColor, 0.15)}, 0 2px 4px ${fade("#000", 0.15)}, 0 16px 32px -8px ${fade("#000", 0.15)}`;

  theme.palette.text.primary = contrastColor;
  theme.palette.text.secondary = fade(contrastColor, 0.66);
  theme.palette.primary.main = site.customisation.colorAccent;
  theme.palette.background.default = site.customisation.colorBackground;

  theme.typography.fontWeightRegular = theme.fontBody.weightRegular;
  theme.typography.fontWeightMedium = theme.fontBody.weightBold;
  theme.typography.fontWeightBold = theme.fontBody.weightBold;
  theme.typography.fontFamily = theme.fontBody.label;
  theme.typography.h1.fontFamily = theme.fontHeader.label;
  theme.typography.h2.fontFamily = theme.fontHeader.label;
  theme.typography.h3.fontFamily = theme.fontBody.label;
  theme.typography.h5.fontFamily = theme.fontHeader.label;
  theme.typography.body1.fontFamily = theme.fontBody.label;
  theme.typography.body2.fontFamily = theme.fontBody.label;
  theme.typography.button.fontFamily = theme.fontBody.label;
  theme.typography.caption.fontFamily = theme.fontBody.label;

  theme.overrides.MuiPaper.root.backgroundColor = site.customisation.colorBackground;

  theme.overrides.MuiButton.containedPrimary.backgroundColor = site.customisation.colorAccent;
  theme.overrides.MuiButton.containedPrimary.color = theme.palette.getContrastText(site.customisation.colorAccent);
  theme.overrides.MuiButton.containedPrimary.fontWeight = theme.fontBody.weightBold;
  theme.overrides.MuiButton.containedPrimary.boxShadow = "none";

  theme.overrides.MuiDivider.root.backgroundColor = fade(contrastColor, 0.15);

  theme.overrides.MuiDialogContent.dividers.borderTopColor = fade(contrastColor, 0.15);

  theme.overrides.MuiChip.root.backgroundColor = fade(contrastColor, 0.15);
  theme.overrides.MuiChip.root.color = contrastColor;

  theme.overrides.MuiListItemIcon.root.color = contrastColor;

  theme.overrides.MuiOutlinedInput.root.color = colorBlack;
  theme.overrides.MuiOutlinedInput.root.boxShadow = `0 0 0 1px ${fade(colorBlack, 0.15)}`;
  theme.overrides.MuiOutlinedInput.root["&$focused $notchedOutline"].boxShadow = `0 0 0 1px ${site.customisation.colorAccent}`;
  theme.overrides.MuiOutlinedInput.notchedOutline.boxShadow = `0 0 0 1px ${fade(contrastColor, 0.15)}`;


  return theme;
}

export default CustomiseThemeBase;
