import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import * as ROUTES from "~/src/constants/routes";
import { Button, Spacing, Link } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    maxWidth: 440,
  },
  textfield: {
    flexGrow: 1,
    marginRight: theme.spacing(),
  },
}));

function SignUpForm({ authUser }) {
  if (authUser) return null;

  const classes = useStyles();

  return (
    <>
      <Spacing />

      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => {
          const { email } = values;

          Router.push(`/sign-up?email=${email}`);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={classes.form}>
              <div className={classes.textfield}>
                <Field
                  placeholder="Your email address..."
                  name="email"
                  component={TextField}
                  type="email"
                />
              </div>
              <Button color="primary" type="submit" loading={isSubmitting}>Sign Up</Button>
            </div>
          </Form>
        )}
      </Formik>

      <Spacing extraTight />

      <Typography color="textSecondary">
        Already have an account?{" "}
        <Link href={ROUTES.LOG_IN}>Log in</Link>
        .
      </Typography>
    </>
  );
}

export default SignUpForm;
