import React from "react";
import { getThumbnailURL } from "~/src/api";
import { Video } from "~/src/components/global";
import { TeacherHeroImage } from "~/src/components/teachers";

const VideoWithFallback = ({ teacher }) => (teacher.video ? (
    <Video
      autoplay
      source={teacher.video}
      poster={getThumbnailURL({
        url: teacher.photo,
        size: 128,
      })}
    />
) : teacher.photo ? (
      <TeacherHeroImage source={getThumbnailURL({
        alt: teacher.firstname,
        url: teacher.photo,
        size: 512,
      })}
      />
) : null);

export default VideoWithFallback;
