import { fade } from "@material-ui/core/styles/colorManipulator";
import variables from "./variables";

const palette = {
  background: {
    default: variables.palette.background.default,
    dark: fade(variables.palette.text.primary, 0.1),
  },
  text: {
    primary: variables.palette.text.primary,
    secondary: variables.palette.text.secondary,
    disabled: variables.palette.text.disabled,
    hint: variables.palette.text.hint,
  },
  primary: {
    light: variables.palette.primary.light,
    main: variables.palette.primary.main,
    dark: variables.palette.primary.dark,
  },
  secondary: {
    main: variables.palette.danger.main,
  },
  success: {
    main: variables.palette.success.main,
  },
  warning: {
    main: variables.palette.warning.main,
  },
  danger: {
    main: variables.palette.danger.main,
  },
  border: {
    light: variables.borders.palette.light,
    base: variables.borders.palette.base,
    dark: variables.borders.palette.dark,
    focus: variables.borders.palette.focus,
  },
};

export default palette;
