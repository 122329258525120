import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

import {
  Card,
  Avatar,
  Strong,
  Description,
} from "~/src/components/global";

function Longform({
  name, photo, text, ...otherProps
}) {
  return (
    <Card elevation={0}>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt={name} src={photo} />
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Typography {...otherProps}>
                <Strong>{name}</Strong>
              </Typography>
            )}
            secondary={<Description {...otherProps} text={text} variant="body1" />}
            disableTypography
          />
        </ListItem>
      </List>
    </Card>
  );
}

export default Longform;
