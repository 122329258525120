import React from "react";
import Error from "~/src/pages/_error.js";
import { getTeacherPhoto } from "~/src/api";

import {
  AppWithAuthentication,
  Head,
  Container,
  Card,
} from "~/src/components/global";

import { TeacherDetail } from "~/src/components/teachers";

const TeacherStandalonePage = (props) => {
  const { teacher, authUser } = props;

  if (!teacher) {
    return <Error />;
  }

  return (
    <AppWithAuthentication>
      <Container>
        <Head
          title={`${teacher.firstname} · Teachers · Bolstr`}
          image={getTeacherPhoto({
            teacher,
            size: 512,
          })}
          description={teacher.description}
        />

        <Card>
          <TeacherDetail
            authUser={authUser}
            teacher={teacher}
          />
        </Card>
      </Container>
    </AppWithAuthentication>
  );
};

export default TeacherStandalonePage;
