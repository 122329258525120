const icons = {
  MuiSvgIcon: {
    root: {
      display: "block",
      fontSize: 24,
    },
  },

};

export default icons;
