import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";

import { Container } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  swap: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      "& $image": {
        left: 0,
        right: "auto",
      },
    },
  },
  imageWrapper: {
    position: "relative",
    paddingBottom: "100%",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      minHeight: 560,
      paddingBottom: 0,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.up("md")]: {
      left: "auto",
      width: "50vw",
    },
  },
  textWrapper: {
    [theme.breakpoints.up("md")]: {
      padding: [[theme.spacing(4), 0]],
    },
  },
}));

const Section = ({
  image, size, children, swap,
}) => {
  const classes = useStyles();

  return (
    <section className={classes.wrapper}>
      <Container size={size || "lg"}>
        <Grid container className={swap ? classes.swap : undefined} alignItems="center">
          <Grid item xs={12} md={6}>
            <div className={classes.imageWrapper}>
              <div className={classes.image} style={{ backgroundImage: `url(${getThumbnailURL({ url: image, size: 1024 })})` }} />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.textWrapper}>
              {children}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Section;
