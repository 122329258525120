import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import {
  Spacing,
  Container,
} from "~/src/components/global";

import {
  VideoWithFallback,
  Longform,
  Links,
} from "~/src/sites";

const useStyles = makeStyles((theme) => ({
  hero: {
    paddingTop: 76,
  },
  h1: {
    position: "relative",
    zIndex: 1,
    marginBottom: theme.spacing(-3),
    textShadow: `0 0 16px ${theme.palette.getContrastText(theme.palette.text.primary)}`,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(-6),
    },
  },
}));

function Hero({ site, teacher, handleOpenDialog }) {
  const classes = useStyles();

  return (
    <section className={classes.hero}>
      <Container size="md">
        <Spacing extraLoose>
          <Typography className={classes.h1} variant="h1" gutterBottom align="center">{site.content && site.content.introTitle}</Typography>

          <Container disableMargins>
            <VideoWithFallback teacher={teacher} />

            <Spacing tight>
              <Longform text={site.content && site.content.introBody} align="center" />
            </Spacing>
            <Links links={site.links} handleOpenDialog={handleOpenDialog} justify="center" />
          </Container>
        </Spacing>
      </Container>
    </section>
  );
}

export default Hero;
