import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";

import {
  Avatar,
  Flex,
  Strong,
  Spacing,
  Button,
} from "~/src/components/global";

import { BannerReplyTime } from "~/src/components/shared";

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    position: "fixed",
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      left: "auto",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      position: "static",
    },
  },
  teacher: {
    width: 0,
    flexGrow: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

function ContactCard({ teacher, handleOpenDialog }) {
  const classes = useStyles();

  const teacherName = `${teacher.firstname} ${teacher.lastname}`;

  return (
    <div className={classes.card}>
      <div className={classes.teacher}>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={teacherName}
                src={getThumbnailURL({
                  url: teacher.photo,
                  size: 128,
                })}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Strong>{teacherName}</Strong>}
              secondary={teacher.location && teacher.location.label}
              primaryTypographyProps={{
                noWrap: true,
                color: "inherit",
                variant: "body1",
              }}
              secondaryTypographyProps={{
                noWrap: true,
                color: "inherit",
                variant: "body1",
              }}
            />
          </ListItem>
        </List>
      </div>

      <Hidden implementation="css" smDown>
        <Spacing tight />

        <Flex spaceChildren="tight" wrap justify="start">
          {teacher.interests && teacher.interests.map((interest, index) => (
            <Chip label={interest} key={index} />
          ))}
        </Flex>

        <Spacing />

        <Button color="primary" fullWidth onClick={handleOpenDialog}>
          Contact {teacher.firstname}
        </Button>

        <BannerReplyTime color="inherit" name={teacher.firstname} avgReplyTime={teacher.avgReplyTime} marginTop />
      </Hidden>

      <Hidden implementation="css" mdUp>
        <Button color="primary" onClick={handleOpenDialog}>
          Contact
        </Button>
      </Hidden>
    </div>
  );
}

export default ContactCard;
