import React from "react";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  Banner,
  Button,
  Spacing,
  FormLayout,
} from "~/src/components/global";

const validationSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address")
    .required("Email address is required"),
});

export default function MailchimpForm({ site }) {
  let url = site.mailchimp;

  if (!url) return null;

  if (!url.includes("/post")) {
    // Transform https://app.us4.list-manage.com/subscribe?u=2f2004af94149b276447fa62d&id=8a3d619473
    // into      https://app.us4.list-manage.com/subscribe/post?u=2f2004af94149b276447fa62d&id=8a3d619473
    const splitUrl = url.split("/subscribe");
    url = `${splitUrl[0]}/subscribe/post${splitUrl[1]}`;
  }

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <>
          {status !== "success" && (
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                subscribe({
                  EMAIL: values.email,
                });
              }}
            >
            {({ isSubmitting }) => (
              <Form>
                <FormLayout>
                  <FormLayout.Group>
                    <Field
                      placeholder="Name"
                      name="name"
                      component={TextField}
                    />
                    <Field
                      placeholder="Email address"
                      name="email"
                      type="email"
                      component={TextField}
                    />
                  </FormLayout.Group>
                  <FormLayout.Footer>
                    <Button
                      color="primary"
                      loading={isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </FormLayout.Footer>
                </FormLayout>
              </Form>
            )}
          </Formik>
          )}
          {status === "sending" && (
            <>
              <Spacing tight />
              <Banner status="info">Sending...</Banner>
            </>
          )}
          {status === "error" && (
            <>
              <Spacing tight />
              <Banner status="danger">{message}</Banner>
            </>
          )}
          {status === "success" && <Banner status="success">Subscribed!</Banner>}
        </>
      )}
    />
  );
}
