import {
  CustomiseThemeBase,
} from "~/src/sites";

function customiseTheme({ site }) {
  const theme = CustomiseThemeBase({ site });

  theme.overrides.MuiButton.contained.backgroundColor = "#fff";
  theme.overrides.MuiButton.contained.color = theme.palette.getContrastText("#fff");

  theme.typography.h1.fontSize = 64;

  return theme;
}

export default customiseTheme;
