import variables from "./variables";

const lists = {
  MuiCard: {
    root: {
      overflow: "visible",
    },
  },
  MuiCardContent: {
    root: {
      "&:last-child": {
        paddingBottom: undefined,
      },
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: undefined,
    },
    elevation0: {
      // background: "transparent",
    },
    elevation2: {

    },
    rounded: {
      borderRadius: variables.shape.borderRadius.large,
    },
  },
};

export default lists;
