import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  CustomiseThemeBase,
} from "~/src/sites";

function customiseTheme({ site }) {
  const theme = CustomiseThemeBase({ site });

  theme.overrides.MuiButton.contained.backgroundColor = fade("#fff", 0.15);
  theme.overrides.MuiButton.contained.color = theme.palette.text.primary;
  theme.overrides.MuiButton.contained.fontWeight = theme.fontBody.weightBold;

  theme.overrides.MuiPaper.elevation0.boxShadow = "none";

  theme.overrides.MuiCardContent.root.padding = 0;

  theme.typography.h1.fontSize = 72;

  return theme;
}

export default customiseTheme;
