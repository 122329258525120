import React from "react";
import {
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";

import {
  Avatar,
  Flex,
  Strong,
  Spacing,
  Button,
} from "~/src/components/global";
import { BannerReplyTime } from "~/src/components/shared";

function ContactCard({ teacher, handleOpenDialog }) {
  const teacherName = `${teacher.firstname} ${teacher.lastname}`;

  return (
    <>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={teacherName}
              src={getThumbnailURL({
                url: teacher.photo,
                size: 128,
              })}
            />
          </ListItemAvatar>
          <ListItemText
            primary={<Strong>{teacherName}</Strong>}
            secondary={teacher.location && teacher.location.label}
            primaryTypographyProps={{
              noWrap: true,
              variant: "body1",
            }}
            secondaryTypographyProps={{
              noWrap: true,
              variant: "body1",
            }}
          />
        </ListItem>
      </List>

      <Spacing tight />

      <Flex spaceChildren="tight" wrap justify="start">
        {teacher.interests && teacher.interests.map((interest, index) => (
          <Chip label={interest} key={index} />
        ))}
      </Flex>

      <Spacing />

      <Button color="primary" fullWidth onClick={handleOpenDialog}>
        Contact {teacher.firstname}
      </Button>

      <BannerReplyTime name={teacher.firstname} avgReplyTime={teacher.avgReplyTime} marginTop />
    </>
  );
}

export default ContactCard;
