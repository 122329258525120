import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Hidden,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";

import {
  Avatar,
  Flex,
  Strong,
  Button,
  Container,
} from "~/src/components/global";

import { BannerReplyTime } from "~/src/components/shared";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[0],
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  flex: {
    display: "flex",
  },
  teacher: {
    width: 0,
    flexGrow: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

function Header({ teacher, handleOpenDialog }) {
  const classes = useStyles();

  const teacherName = `${teacher.firstname} ${teacher.lastname}`;

  return (
    <div className={classes.header}>
      <Container disableMargins size="lg">
        <div className={classes.flex}>
          <div className={classes.teacher}>
            <List>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt={teacherName}
                    src={getThumbnailURL({
                      url: teacher.photo,
                      size: 128,
                    })}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Strong>{teacherName}</Strong>}
                  secondary={teacher.location && teacher.location.label}
                  primaryTypographyProps={{
                    noWrap: true,
                    variant: "body1",
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                    variant: "body1",
                  }}
                />
              </ListItem>
            </List>
          </div>

          <Hidden implementation="css" smDown>
            <Flex spaceChildren>
              <BannerReplyTime name={teacher.firstname} avgReplyTime={teacher.avgReplyTime} />

              <Button color="primary" fullWidth onClick={handleOpenDialog}>
                Contact {teacher.firstname}
              </Button>
            </Flex>
          </Hidden>

          <Hidden implementation="css" mdUp>
            <Button color="primary" onClick={handleOpenDialog}>
              Contact
            </Button>
          </Hidden>
        </div>
      </Container>
    </div>
  );
}

export default Header;
