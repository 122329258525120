import React from "react";
import Router from "next/router";
import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import { Typography } from "@material-ui/core";
import * as ROUTES from "~/src/constants/routes";
import * as ROUTES_ADMIN from "~/src/constants/routesAdmin";
import { firebase } from "~/src/api";
import {
  FormLayout, Link, Banner, Button,
} from "~/src/components/global";
import EMAIL_SETTINGS_INITIAL_VALUES from "~/src/constants/initialValues/emailSettings";

const validationSchema = yup.object().shape({
  email: yup.string().email("Must be a valid email address")
    .required("Email address is required"),
  password: yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  passwordConfirm: yup.string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password confirm is required"),
});


const SignUpForm = ({ job, studio, claim }) => {
  const [error, setError] = React.useState(false);

  const signUp = async (values, setSubmitting) => {
    const { email, password } = values;

    console.log("doCreateUserWithEmailAndPassword");
    // Create new auth

    try {
      const authUser = await firebase.doCreateUserWithEmailAndPassword(email, password);
      const authID = authUser.user.uid;

      console.log("create new user");
      // Create new user, copying old data
      await firebase.user(authID).set({
        email,
        authID,
        studio: studio.id,
        emailSettings: EMAIL_SETTINGS_INITIAL_VALUES,
      });

      console.log("modify existing studio data");
      // Assign existing studio to new user
      await firebase.studio(studio.id).update({
        authID,
        email,
        registered: true,
      });

      console.log("loop over all jobs, updating data");
      const jobs = await firebase.jobs().where("studio", "==", studio.id).get();
      const promises = jobs.docs.map((job) => firebase.job(job.id).update({
        authID,
      }));
      await Promise.all(promises);

      // Delete claim doc from studio
      console.log("delete claim doc from studio");
      // Assign existing studio to new user
      await firebase.studioClaim(studio.id, claim).delete();
      // TODO...


      console.log("send email verification");
      await firebase.doSendEmailVerification();

      Router.push(ROUTES.PROFILE_YOUR_JOBS);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: job && job.source && job.source.type && job.source.type === "email" ? job.source.link : "",
        password: "",
        passwordConfirm: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setError(null);
        signUp(values, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <FormLayout>
            {error && <Banner status="danger">{error.message}</Banner>}

            <Field
              label="Email Address"
              name="email"
              component={TextField}
              type="email"
              helperText="You‘ll use this to log in"
            />
            <Field
              label="Password"
              name="password"
              component={TextField}
              type="password"
              autoComplete="new-password"
              helperText="At least 6 characters"
            />
            <Field
              label="Confirm Password"
              name="passwordConfirm"
              component={TextField}
              type="password"
              autoComplete="new-password"
            />
            <Typography>By clicking Sign Up, you agree to our <Link href={ROUTES.TERMS_AND_CONDITIONS} external>Terms & Conditions</Link> and <Link href={ROUTES.PRIVACY_POLICY} external>Privacy Policy</Link>.</Typography>
            <FormLayout.Footer>
              <Link href={ROUTES_ADMIN.CLAIM_JOB} as={`/jobs/${job.id}/claim/${claim}`} unstyled>
                <Button>Back</Button>
              </Link>

              <Button
                color="primary"
                loading={isSubmitting}
                type="submit"
              >
                Sign Up
              </Button>
            </FormLayout.Footer>
          </FormLayout>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
