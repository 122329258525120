import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
} from "@material-ui/core";

import {
  Container,
  Spacing,
} from "~/src/components/global";

import {
  ContactDialog,
  Offering,
  Qualifications,
  Testimonial,
  Longform,
  Footer,
  MailchimpForm,
  Wrapper,
} from "~/src/sites";

import {
  CustomiseTheme,
  Section,
  Header,
  Hero,
} from "./components";

function ThemeCream({ teacher, site, user }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const theme = CustomiseTheme({ site });

  const photos = !teacher.photos ? [null, null, null, null] : teacher.video ? teacher.photos.slice(0, 3) : teacher.photos.slice(1, 4);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper theme={theme}>
        <Header teacher={teacher} handleOpenDialog={handleOpenDialog} />

        {/* Video and Intro */}
        <Hero site={site} teacher={teacher} handleOpenDialog={handleOpenDialog} />

        {/* Description */}
        <Section image={photos[0]}>
          <Typography variant="h2" gutterBottom>{site.content && site.content.descriptionTitle}</Typography>
          <Longform text={site.content && site.content.descriptionBody} />
        </Section>

        {/* Offerings */}
        {(site.offerings && site.offerings.length) ? (
          <Container size="lg">
            <Spacing extraLoose>
              <Typography variant="h2">Learn with {teacher.firstname}</Typography>
              <Spacing />
              <Grid container>
                {site.offerings.map((offering, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Offering
                      title={offering.title}
                      body={offering.text}
                      button={{
                        label: offering.button,
                        url: offering.url,
                      }}
                      handleOpenDialog={handleOpenDialog}
                    />
                  </Grid>
                ))}
              </Grid>
            </Spacing>
          </Container>
        ) : null}

        {/* Qualifications */}
        <Section image={photos[1]} swap>
          <Typography variant="h2">Qualifications</Typography>
          <Spacing />
          <Qualifications teacher={teacher} wrap />
        </Section>

        {/* Testimonials */}
        {(site.testimonials && site.testimonials.length) ? (
          <Container size="lg">
            <Spacing extraLoose>
              <Typography variant="h2">Testimonials</Typography>
              <Spacing />
              <Grid container>
                {site.testimonials.map((testimonial, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Testimonial
                      name={testimonial.name}
                      photo={testimonial.photo}
                      text={testimonial.text}
                    />
                  </Grid>
                ))}
              </Grid>
            </Spacing>
          </Container>
        ) : null}

        {/* Newsletter */}
        {site.mailchimp && (
          <Section image={photos[2]}>
            <Typography variant="h2" gutterBottom>{site.content && site.content.newsletterTitle}</Typography>
            <Typography variant="body2">{site.content && site.content.newsletterBody}</Typography>
            <Spacing />
            <MailchimpForm site={site} />
          </Section>
        )}

        <Footer site={site} teacher={teacher} />

        <ContactDialog user={user} site={site} teacher={teacher} dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} />
      </Wrapper>
    </ThemeProvider>
  );
}

export default ThemeCream;
