import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ContactCard from "./ContactCard";

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  main: {
    [theme.breakpoints.up("md")]: {
      width: 0,
      flexGrow: 1,
      paddingRight: theme.spacing(3),
    },
  },
  stickyCard: {
    [theme.breakpoints.up("md")]: {
      width: "25%",
      height: "100%",
      position: "sticky",
      top: theme.spacing(3),
    },
  },
}));

const Layout = ({
  teacher, handleOpenDialog, children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <main className={classes.main}>
        {children}
      </main>
      <aside className={classes.stickyCard}>
        <ContactCard teacher={teacher} handleOpenDialog={handleOpenDialog} />
      </aside>
    </div>
  );
};

export default Layout;
