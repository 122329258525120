import defaultTheme from "@material-ui/core/styles/defaultTheme";
import variables from "./variables";
import props from "./props";
import palette from "./palette";
import buttons from "./buttons";
import lists from "./lists";
import cards from "./cards";
import icons from "./icons";
import overrides from "./overrides";

const themeOverride = {
  props,
  palette,
  overrides: {
    ...buttons,
    ...lists,
    ...cards,
    ...icons,
    ...overrides,
  },
  typography: {
    color: "red",
    fontSize: variables.typography.base.fontSize,
    fontWeightRegular: variables.typography.fontWeightRegular,
    fontWeightMedium: variables.typography.fontWeightMedium,
    fontWeightBold: variables.typography.fontWeightBold,
    body1: {
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
      letterSpacing: undefined,
    },
    body2: {
      fontSize: variables.typography.body2.fontSize,
      lineHeight: variables.typography.body2.lineHeight,
      letterSpacing: undefined,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: variables.typography.base.fontSize,
      },
    },
    caption: {
      fontSize: variables.typography.caption.fontSize,
      lineHeight: variables.typography.caption.lineHeight,
      letterSpacing: undefined,
    },
    button: {
      textTransform: "none",
      fontSize: variables.typography.base.fontSize,
      lineHeight: variables.typography.base.lineHeight,
      fontWeight: 600,
      letterSpacing: undefined,
    },
    h1: {
      fontSize: variables.typography.h1.fontSize,
      fontWeight: variables.typography.h1.fontWeight,
      lineHeight: variables.typography.h1.lineHeight,
      letterSpacing: undefined,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: variables.typography.h2.fontSize,
        lineHeight: variables.typography.h2.lineHeight,
      },
    },
    h2: {
      fontSize: variables.typography.h2.fontSize,
      fontWeight: variables.typography.h2.fontWeight,
      lineHeight: variables.typography.h2.lineHeight,
      letterSpacing: undefined,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: variables.typography.h3.fontSize,
        lineHeight: variables.typography.h3.lineHeight,
      },
    },
    h3: {
      fontSize: variables.typography.h3.fontSize,
      fontWeight: variables.typography.h3.fontWeight,
      lineHeight: variables.typography.h3.lineHeight,
      letterSpacing: undefined,
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: variables.typography.h4.fontSize,
        lineHeight: variables.typography.h4.lineHeight,
      },
    },
  },
};

export default themeOverride;
