import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid, Typography,
} from "@material-ui/core";

import StarIcon from "@material-ui/icons/StarBorder";
import MoodIcon from "@material-ui/icons/Mood";
import PlayIcon from "@material-ui/icons/PlayCircleOutline";
import InsuranceIcon from "@material-ui/icons/VerifiedUserOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import LinkIcon from "@material-ui/icons/Link";
import PaletteIcon from "@material-ui/icons/PaletteOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";
import BlockIcon from "@material-ui/icons/Block";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FilterListIcon from "@material-ui/icons/FilterList";
import EventIcon from "@material-ui/icons/Event";
import LOCATIONS_GEO from "~/src/constants/locationsGeo";
import SKILLS from "~/src/constants/skills";

import {
  AppWithAuthentication, Head, Layout, Flex, Emoji, Link, Spacing, Divider,
} from "~/src/components/global";
import { Hero } from "~/src/components/content";
import JumpLinks from "./JumpLinks";
import PitchPreview from "./PitchPreview";
import Review from "./Review";
import Form from "./Form";

const useStyles = makeStyles((theme) => ({
  listItem: {
    fontSize: 17,
    marginTop: -2,
    maxWidth: 400,
  },
  text: {
    marginRight: theme.spacing(),
  },
  imageGridItem: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  link: {
    cursor: "pointer",
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function HomePage({ authUser }) {
  const classes = useStyles();

  const renderList = (icon, text, soon) => (
      <ListItem>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={(
            <>
              <span className={classes.text}>{text}</span>
              {soon && <Chip label="Coming soon" />}
            </>
          )}
          primaryTypographyProps={{
            variant: "body2",
            className: classes.listItem,
          }}
        />
      </ListItem>
  );

  return (
    <AppWithAuthentication>
      <Layout>
        <Head
          title="Bolstr · Connecting studios and gyms with health and fitness instructors"
        />
          <Hero
            title="Bolstr connects studios and gyms with health and fitness instructors"
            image="https://images.unsplash.com/photo-1542990254-6a8126153b7a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&h=360&q=80"
            imageAlt="Yoga Teacher"
            alignItems="center"
          >
            <Spacing tight />

            <Flex justify="start" spaceChildren wrap>
              {SKILLS.map((skill) => (
                <div key={skill.name}>
                  <Emoji>{skill.emoji}</Emoji>
                  {skill.label}
                </div>
              ))}
            </Flex>

            <Form authUser={authUser} />
          </Hero>

        <Spacing extraLoose>
          <Divider extraLoose />
        </Spacing>

        <JumpLinks />

        <Spacing extraLoose>
          <Divider extraLoose />
        </Spacing>

        <section id="video">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <PitchPreview />
            </Grid>
            <Grid item xs={12} md={6}>
              <Review>
                I landed a corporate gig in the first week. The platform works.
              </Review>

              <Typography variant="h1" gutterBottom>
                Video applications are more effective than resumes.
              </Typography>

              <Spacing tight />

              <List>
                {renderList(<MoodIcon />, "Personalised to the company and job")}
                {renderList(<PlayIcon />, "Intro video showing your personality")}
                {renderList(<StarIcon />, "Verified reviews and references", true)}
                {renderList(<InsuranceIcon />, "Insurance and first aid qualifications")}
              </List>

              <Form authUser={authUser} />
            </Grid>
          </Grid>
        </section>

        <Spacing extraLoose>
          <Divider extraLoose />
        </Spacing>

        <section id="jobs">
          <Hero
            pretitle={(
              <Review>
                I’m so grateful for this amazing platform to connect teachers and studios. Other than social media, there’s no platform in the yoga industry. This is what we needed!
              </Review>
            )}
            title="The latest jobs in health and fitness, in your inbox."
            image="/home/email.png"
            imageAlt="Latest Jobs Email"
          >
            <Spacing tight />

            <List>
              {renderList(<ScheduleIcon />, "Choose to get updated twice daily, daily, or weekly", true)}
              {renderList(<FilterListIcon />, "See only the jobs you’re interested in")}
              {renderList(<BlockIcon />, "No more trawling Facebook groups and job boards")}
              {renderList(<EventIcon />, "Advertise your upcoming events, workshops and teacher trainings", true)}
            </List>

            <Form authUser={authUser} />
          </Hero>
        </section>

        <Spacing extraLoose>
          <Divider extraLoose />
        </Spacing>

        <section id="website">
          <Hero
            pretitle={(
              <Review>
                Bolstr really lets me express my unique style and personality!
              </Review>
            )}
            title="Get online with a beautiful, customisable website."
            image="/home/website.png"
            imageAlt="Beautiful Website"
            reverse
          >
            <Spacing tight />

            <List>
              {renderList(<LanguageIcon />, "Your own website")}
              {renderList(<LinkIcon />, "Custom domain name")}
              {renderList(<PaletteIcon />, "Beautiful, customisable themes")}
              {renderList(<AccountCircleIcon />, "Link to your social media and online classes")}
              {renderList(<BlockIcon />, "No design or development experience required")}
              {renderList(<MoneyOffIcon />, "Cheaper than Squarespace and Wix—put together!")}
            </List>

            <Form authUser={authUser} />
          </Hero>
        </section>

        <Spacing extraLoose>
          <Divider extraLoose />
        </Spacing>

        <section id="links">
          <Grid container>
            {SKILLS.map((skill) => LOCATIONS_GEO.map((location) => (
                <Grid item xs={6} md={4} lg={3}>
                  <Link href="/jobs/[id]/[discipline]" as={`/jobs/${location.name}/${skill.name}`} unstyled className={classes.link} target="_blank" rel="noopener">
                    <Typography>
                      {skill.label} jobs in {location.h1}
                    </Typography>
                  </Link>
                </Grid>
            )))}
            </Grid>
        </section>
      </Layout>
    </AppWithAuthentication>
  );
}

export default HomePage;
