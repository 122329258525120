import React from "react";
import { Button } from "@material-ui/core";
import { Flex } from "~/src/components/global";

function LinkList({ links, handleOpenDialog, justify }) {
  return (
    <Flex spaceChildren="tight" wrap justify={justify || "start"}>
      {links && links.map((link, index) => (link.url ? (
        <Button href={link.url} key={index} target="_blank" rel="noopener noreferrer">
          {link.label}
        </Button>
      ) : (
        <Button onClick={handleOpenDialog} key={index}>
          {link.label}
        </Button>
      )))}
    </Flex>
  );
}

export default LinkList;
