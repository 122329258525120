import React from "react";
import { Typography, Grid } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  Container, Button, Spacing, Card,
} from "~/src/components/global";

function JumpLinks() {
  return (
    <Container size="md" disableGutters disableMargins>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Card>
            <Typography variant="h5" color="textPrimary">Visual Resumes</Typography>
            <Spacing extraTight>
              <Typography color="textSecondary">
                CVs are so 2019. Bolstr Visual Resumes have videos, images, qualifications, work experience, and more.
              </Typography>
            </Spacing>
            <Button startIcon={<ArrowDownwardIcon />} variant="text" color="primary" href="#video">
              Learn more
            </Button>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <Typography variant="h5" color="textPrimary">Daily Job Listings</Typography>
            <Spacing extraTight>
              <Typography color="textSecondary">
                Studios post jobs for free.
                Get the latest jobs in your inbox—instantly, daily, or weekly. No more trawling Facebook groups.
              </Typography>
            </Spacing>
            <Button startIcon={<ArrowDownwardIcon />} variant="text" color="primary" href="#jobs">
              Learn more
            </Button>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card>
            <Typography variant="h5" color="textPrimary">Host Your Website</Typography>
            <Spacing extraTight>
              <Typography color="textSecondary">
                Choose from beautiful, themes, pick a domain name, and link to your online offerings.
              </Typography>
            </Spacing>
            <Button startIcon={<ArrowDownwardIcon />} variant="text" color="primary" href="#website">
              Learn more
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default JumpLinks;
