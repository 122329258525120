import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getThumbnailURL } from "~/src/api";

import {
  Container,
  Spacing,
} from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: "white",
    textShadow: "0 1px rgba(0,0,0,0.25), 0 0 16px black",
    background: "black",
    position: "relative",
    textAlign: "center",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 320,
    [theme.breakpoints.up("md")]: {
      minHeight: 560,
    },
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 0.8,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(-0.5),
  },
  containerInner: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  item: {
    padding: theme.spacing(0.5),
    width: "50%",
  },
}));

const Gallery = ({ image, size, children }) => {
  const classes = useStyles();

  return (
    <section className={classes.wrapper}>
      <div className={classes.backgroundImage} style={{ backgroundImage: `url(${getThumbnailURL({ url: image, size: 1024 })})` }} />
      <Container size={size || "sm"}>
        <Spacing extraLoose>
          {children}
        </Spacing>
      </Container>
    </section>
  );
};

export default Gallery;
