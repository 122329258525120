export const LOCATIONS_GEO = [
  {
    name: "sydney",
    latitude: -33.8688197,
    longitude: 151.2092955,
    label: "Sydney NSW, Australia",
    h1: "Sydney",
  },
  {
    name: "brisbane",
    latitude: -27.4697707,
    longitude: 153.0251235,
    label: "Brisbane QLD, Australia",
    h1: "Brisbane",
  },
  {
    name: "gold-coast",
    latitude: -28.016667,
    longitude: 153.4,
    label: "Gold Coast QLD, Australia",
    h1: "Gold Coast",
  },
  {
    name: "inner-west",
    latitude: -33.8584827,
    longitude: 151.1286686,
    label: "Inner West, NSW, Australia",
    h1: "Sydney’s Inner West",
  },
  {
    name: "Redfern",
    latitude: -33.8931067,
    longitude: 151.2024146,
    label: "Redfern, NSW, Australia",
    h1: "Redfern",
  },
  {
    name: "camperdown",
    latitude: -33.8886608,
    longitude: 151.1743642,
    label: "Camperdown, NSW, Australia",
    h1: "Camperdown",
  },
  {
    name: "darlinghurst",
    latitude: -33.8794164,
    longitude: 151.2164518,
    label: "Darlinghurst, NSW, Australia",
    h1: "Darlinghurst",
  },
  {
    name: "castle-hill",
    latitude: -33.7302235,
    longitude: 150.9801632,
    label: "Castle Hill, NSW, Australia",
    h1: "Castle Hill",
  },
  {
    name: "auburn",
    latitude: -33.8578327,
    longitude: 151.0107403,
    label: "Auburn, NSW, Australia",
    h1: "Auburn",
  },
  {
    name: "balmain",
    latitude: -33.8583272,
    longitude: 151.1719389,
    label: "Balmain, NSW, Australia",
    h1: "Balmain",
  },
  {
    name: "sydney-cbd",
    latitude: -33.8708464,
    longitude: 151.20733,
    label: "Sydney CBD, NSW, Australia",
    h1: "Sydney CBD",
  },
];

export default LOCATIONS_GEO;
