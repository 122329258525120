import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  CustomiseThemeBase,
} from "~/src/sites";

function customiseTheme({ site }) {
  const theme = CustomiseThemeBase({ site });

  const blackColor = theme.palette.getContrastText("#fff");

  theme.overrides.MuiPaper.root.color = blackColor;
  theme.overrides.MuiPaper.root.backgroundColor = "#fff";

  theme.overrides.MuiDialog.paper.color = theme.palette.text.primary;
  theme.overrides.MuiDialog.paper.backgroundColor = theme.palette.background.default;

  theme.overrides.MuiChip.root.backgroundColor = fade(blackColor, 0.15);
  theme.overrides.MuiChip.root.color = blackColor;

  return theme;
}

export default customiseTheme;
