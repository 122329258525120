import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";
import { Container, Spacing } from "~/src/components/global";
import { TeacherGallery, TeacherHeroImage } from "~/src/components/teachers";
import {
  ContactDialog,
  Offering,
  Qualifications,
  Testimonial,
  Longform,
  Divider,
  Footer,
  Links,
  MailchimpForm,
  Wrapper,
} from "~/src/sites";

import {
  Layout,
  CustomiseTheme,
} from "./components";

function ThemeCream({ teacher, site, user }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const theme = CustomiseTheme({ site });
  const morePhotos = !teacher.photos ? [null, null, null] : teacher.video ? teacher.photos.slice(4, 7) : teacher.photos.slice(5, 8);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper theme={theme}>
        <Container size="lg">
          <TeacherGallery teacher={teacher} />

          <Layout teacher={teacher} handleOpenDialog={handleOpenDialog}>
            <Typography variant="h1" gutterBottom>{site.content && site.content.introTitle}</Typography>
            <Longform text={site.content && site.content.introBody} color="textSecondary" />
            <Spacing tight />
            <Links links={site.links} handleOpenDialog={handleOpenDialog} />

            <Divider />
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6}>
                <TeacherHeroImage
                  source={getThumbnailURL({
                    alt: teacher.firstname,
                    url: morePhotos[0],
                    size: 1024,
                  })}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h2" gutterBottom>{site.content && site.content.descriptionTitle}</Typography>
                <Longform text={site.content && site.content.descriptionBody} />
              </Grid>
            </Grid>

            {(site.offerings && site.offerings.length) ? (
              <>
                <Divider />
                <Typography variant="h2">Learn with {teacher.firstname}</Typography>
                <Spacing />
                <Grid container>
                  {site.offerings.map((offering, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Offering
                        title={offering.title}
                        body={offering.text}
                        button={{
                          label: offering.button,
                          url: offering.url,
                        }}
                        handleOpenDialog={handleOpenDialog}
                        color="inherit"
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : null}

            <Divider />
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h2">Qualifications</Typography>
                <Spacing />
                <Qualifications teacher={teacher} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TeacherHeroImage
                  source={getThumbnailURL({
                    alt: teacher.firstname,
                    url: morePhotos[1],
                    size: 1024,
                  })}
                />
              </Grid>
            </Grid>

            {(site.testimonials && site.testimonials.length) ? (
              <>
                <Divider />
                <Typography variant="h2">Testimonials</Typography>
                <Spacing />
                <Grid container>
                  {site.testimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Testimonial
                        name={testimonial.name}
                        photo={testimonial.photo}
                        text={testimonial.text}
                        color="inherit"
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : null}

            {site.mailchimp && (
              <>
                <Divider />
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <TeacherHeroImage
                      source={getThumbnailURL({
                        alt: teacher.firstname,
                        url: morePhotos[2],
                        size: 1024,
                      })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="h2" gutterBottom>{site.content && site.content.newsletterTitle}</Typography>
                    <Typography variant="body2">{site.content && site.content.newsletterBody}</Typography>
                    <Spacing />
                    <MailchimpForm site={site} />
                  </Grid>
                </Grid>
              </>
            )}
          </Layout>
        </Container>

        <ContactDialog user={user} site={site} teacher={teacher} dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} />

        <Footer site={site} teacher={teacher} />
      </Wrapper>
    </ThemeProvider>
  );
}

export default ThemeCream;
