import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

import {
  Container,
  Link,
} from "~/src/components/global";

import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  YoutubeIcon,
} from "~/src/icons";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: fade(theme.palette.text.primary, 0.15),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  copyright: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  social: {
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "center",
    "& * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  poweredBy: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  link: {
    color: "inherit",
    textDecoration: "underline",
  },
}));

function Footer({ teacher, site }) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container size="lg">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className={classes.copyright}>
              © 2020 {teacher.firstname} {teacher.lastname}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.social}>
            {site.social && (
              <>
                {site.social.facebook && <Link href={site.social.facebook} external unstyled><FacebookIcon /></Link>}
                {site.social.instagram && <Link href={site.social.instagram} external unstyled><InstagramIcon /></Link>}
                {site.social.twitter && <Link href={site.social.twitter} external unstyled><TwitterIcon /></Link>}
                {site.social.youtube && <Link href={site.social.youtube} external unstyled><YoutubeIcon /></Link>}
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className={classes.poweredBy}>
              Powered by <a className={classes.link} href="https://bolstr.app/" target="_blank" rel="noopener noreferrer">Bolstr</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
