const props = {
  MuiAvatar: {
    variant: "circle",
  },
  MuiDialog: {
    transitionDuration: 0,
    maxWidth: "xs",
    scroll: "body",
    PaperProps: {
      elevation: 2,
    },
  },
  MuiSnackbar: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
  MuiSnackbarContent: {
    elevation: 0,
  },
  MuiPopover: {
    elevation: 2,
  },


  MuiList: {
    disablePadding: true,
  },
  MuiListItem: {
    disableGutters: false,
  },
  MuiButtonBase: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  MuiButton: {
    variant: "contained",
  },
  MuiChip: {
    size: "small",
  },
  MuiGrid: {
    spacing: 3,
  },


  MuiInputLabel: {
  },
  MuiFormHelperText: {
  },
  MuiTextField: {
    variant: "outlined",
  },
  MuiSelect: {
    variant: "outlined",
  },
  MuiOutlinedInput: {
    notched: false,
  },
  MuiCheckbox: {
    color: "primary",
  },
  MuiRadio: {
    color: "primary",
  },
  MuiTooltip: {
    placement: "top",
    enterTouchDelay: 0,
    leaveTouchDelay: 1500,
    TransitionProps: {
      timeout: 0,
    },
  },
  MuiTypography: {
    color: "textPrimary",
    display: "block",
  },
  MuiTabs: {
    textColor: "primary",
    indicatorColor: "primary",
    variant: "scrollable",
  },
};

export default props;
