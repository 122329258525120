import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: 250,
    borderRadius: theme.shape.borderRadiusLarge,
    boxShadow: theme.shadows[1],
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
  },
  iframe: {
    border: "none",
    borderRadius: theme.shape.borderRadiusLarge,
    transformOrigin: "0 0",
    pointerEvents: "none",
    zIndex: -1000,
    transform: "scale(0.333)",
    width: "300%",
    height: 750,
    [theme.breakpoints.up("sm")]: {
      transform: "scale(0.5)",
      width: "200%",
      height: 800,
    },
  },
}));

function PitchPreview() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <iframe title="Preview Iframe" src="https://bolstr.app/application/morgan/3u9QzVADoV14ELOi4u1R" className={classes.iframe} />
      </div>
    </>
  );
}

export default PitchPreview;
