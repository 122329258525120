import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/StarBorder";
import { Spacing } from "~/src/components/global";

const useStyles = makeStyles((theme) => ({
  stars: {
    display: "flex",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
}));

function Review({ children }) {
  if (!children) return null;

  const classes = useStyles();

  return (
    <>
      <div className={classes.stars}>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </div>
      <Typography color="textSecondary">“{children}”</Typography>

      <Spacing tight />
    </>
  );
}

export default Review;
