import React from "react";
import { getTeacherPhoto } from "~/src/api";

import {
  AppWithAuthentication,
  Head,
  Container,
  Banner,
} from "~/src/components/global";

import {
  ThemeDefault,
  ThemeNamaste,
  ThemeMindful,
} from "~/src/sites";

const SitePage = ({
  teacher, site, user, preview,
}) => {
  let siteMarkup;
  if (user.subscription === "active" || preview) {
    if (site.customisation.theme === "default") {
      siteMarkup = <ThemeDefault teacher={teacher} site={site} user={user} />;
    } else if (site.customisation.theme === "namaste") {
      siteMarkup = <ThemeNamaste teacher={teacher} site={site} user={user} />;
    } else if (site.customisation.theme === "mindful") {
      siteMarkup = <ThemeMindful teacher={teacher} site={site} user={user} />;
    }
  } else {
    siteMarkup = (
      <Container size="xs">
        <Banner status="warning">{teacher.firstname} must purchase a subscription to Bolstr Pro to make this site visible to the public.</Banner>
      </Container>
    );
  }

  return (
    <AppWithAuthentication>
      <Head
        title={site && site.seo && site.seo.title || teacher.firstname}
        fontBody={site.customisation.fontBody}
        fontHeader={site.customisation.fontHeader}
        image={getTeacherPhoto({
          teacher,
          size: 512,
        })}
        favicon={getTeacherPhoto({
          teacher,
          favicon: true,
        })}
        canonicalUrl={`https://${site.domain}`}
        description={site && site.seo && site.seo.description}
      />

      {siteMarkup}

    </AppWithAuthentication>
  );
};

export default SitePage;
