import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { getThumbnailURL } from "~/src/api";

import {
  Container,
  Spacing,
  Card,
  Video,
} from "~/src/components/global";

import {
  ContactDialog,
  Offering,
  Qualifications,
  Testimonial,
  Longform,
  Footer,
  Links,
  MailchimpForm,
  Wrapper,
} from "~/src/sites";

import {
  CustomiseTheme,
  Section,
  ContactCard,
} from "./components";

function ThemeCream({ teacher, site, user }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const theme = CustomiseTheme({ site });

  const photos = !teacher.photos ? [null, null, null, null] : teacher.video ? teacher.photos.slice(0, 4) : teacher.photos.slice(1, 5);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper theme={theme}>
        <Section image={photos[0]}>
          <Typography variant="h1" gutterBottom color="inherit">{site.content && site.content.introTitle}</Typography>
          <Longform text={site.content && site.content.introBody} color="inherit" />
          <Spacing tight />
          <Links links={site.links} handleOpenDialog={handleOpenDialog} justify="center" />
        </Section>

        {/* Video and ContactCard */}
        <Container size="md">
          <Spacing extraLoose>
    <Card elevation={0}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6}>
                <Video
                  autoplay
                  source={teacher.video}
                  poster={getThumbnailURL({
                    url: teacher.photo,
                    size: 128,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactCard teacher={teacher} handleOpenDialog={handleOpenDialog} />
              </Grid>
            </Grid>
          </Card>
          </Spacing>
        </Container>

        {/* Description */}
        <Section image={photos[1]}>
          <Typography variant="h2" gutterBottom color="inherit">{site.content && site.content.descriptionTitle}</Typography>
          <Longform color="inherit" text={site.content && site.content.descriptionBody} />
        </Section>

        {/* Offerings */}
        {(site.offerings && site.offerings.length) ? (
          <Container size="lg">
            <Spacing extraLoose>
              <Typography variant="h2" align="center">Learn with {teacher.firstname}</Typography>
              <Spacing />
              <Grid container justify="center">
                {site.offerings.map((offering, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Offering
                      title={offering.title}
                      body={offering.text}
                      button={{
                        label: offering.button,
                        url: offering.url,
                      }}
                      handleOpenDialog={handleOpenDialog}
                    />
                  </Grid>
                ))}
              </Grid>
            </Spacing>
          </Container>
        ) : null}

        {/* Qualifications */}
        <Section image={photos[2]} size="md">
          <Typography variant="h2" color="inherit">Qualifications</Typography>
          <Spacing />
          <Qualifications teacher={teacher} inheritColor wrap />
        </Section>

        {/* Testimonials */}
        {(site.testimonials && site.testimonials.length) ? (
          <Container size="lg">
            <Spacing extraLoose>
              <Typography variant="h2" align="center">Testimonials</Typography>
              <Spacing />
              <Grid container justify="center">
                {site.testimonials.map((testimonial, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Testimonial
                      name={testimonial.name}
                      photo={testimonial.photo}
                      text={testimonial.text}
                    />
                  </Grid>
                ))}
              </Grid>
            </Spacing>
          </Container>
        ) : null}

        {/* Newsletter */}
        {site.mailchimp && (
          <Section image={photos[3]}>
            <Typography variant="h2" gutterBottom color="inherit">{site.content && site.content.newsletterTitle}</Typography>
            <Typography variant="body2" color="inherit">{site.content && site.content.newsletterBody}</Typography>
            <Spacing />
            <MailchimpForm site={site} />
          </Section>
        )}

        <Footer site={site} teacher={teacher} />

        <ContactDialog user={user} site={site} teacher={teacher} dialogOpen={dialogOpen} handleCloseDialog={handleCloseDialog} />
      </Wrapper>
    </ThemeProvider>
  );
}

export default ThemeCream;
