import variables from "./variables";

const lists = {
  MuiList: {
    root: {
      margin: [[-2 * variables.spacing]],
    },
    padding: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  MuiListItem: {
    root: {
      paddingTop: variables.spacing * 2,
      paddingBottom: variables.spacing * 2,
      "& + $root": {
        paddingTop: 0,
      },
    },
    button: {
      transition: undefined,
    },
  },
  MuiListItemAvatar: {
    alignItemsFlexStart: {
      marginTop: 2,
    },
  },
  MuiListItemIcon: {
    root: {
      color: variables.palette.text.primary,
      minWidth: 40,
      alignSelf: "flex-start",
    },
  },
  MuiListItemText: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
    multiline: {
      marginTop: undefined,
      marginBottom: undefined,
    },
  },
  MuiMenu: {
    list: {
      margin: 0,
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: undefined,
      paddingTop: 12,
      paddingBottom: 12,
      "& + $root": {
        paddingTop: 12,
      },
    },
  },
};

export default lists;
