import React from "react";
import Router from "next/router";
import { Typography } from "@material-ui/core";
import { analytics, getJobToBeClaimed } from "~/src/api";
import {
  AppWithAuthentication,
  Head,
  Layout,
  Card,
  Link,
  Loading,
  Banner,
  Spacing,
} from "~/src/components/global";
import { JobGridItem } from "~/src/components/jobs";
import Form from "./Form";


class ClaimJobAndStudioSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sentLoadRequest: false,
    };
  }

  loadData = async () => {
    console.log("edit this comment to make cypress work -_-");
    // TODO: do these need to be separate to avoid race conditions?
    this.setState({ sentLoadRequest: true });

    const result = await getJobToBeClaimed({
      jobID: Router.query.id,
      claimID: Router.query.claimID,
    });

    this.setState({
      loading: false,
      job: result ? result.job : null,
      claim: result ? result.claim : null,
    });
    analytics.event("job_claim_continue");
  }

  componentDidMount = async () => {
    if (Router.query.id && !this.state.sentLoadRequest) this.loadData();
  }

  componentDidUpdate = async () => {
    if (Router.query.id && !this.state.sentLoadRequest) this.loadData();
  }

  renderLoadedContent() {
    const { job, claim } = this.state;

    return !job ? (
      <Banner status="danger">Job not found.</Banner>
    ) : job.studio && job.studio.registered ? (
      <Banner status="danger">This job and profile have already been claimed.</Banner>
    ) : !claim ? (
      <Banner status="danger">
        Invalid URL. If you think this is a mistake, please <Link href="mailto:hello@morgancarter.com.au" external>contact support</Link>.
      </Banner>
    ) : (
      <>
        <JobGridItem job={job} />

        <Spacing />

        <Card title="Your Details" h1>

        <Form job={job} studio={job.studio} claim={claim} />
      </Card>
      </>
    );
  }

  render() {
    const { loading } = this.state;

    return (
      <AppWithAuthentication>
        <Layout size="sm">
          <Head
            title="Sign Up · Bolstr"
            dontScrape
          />

          <Typography variant="h1" gutterBottom>Sign Up</Typography>
          <Typography variant="h3" color="textSecondary">Claim this job listing and studio profile</Typography>

          <Spacing />

          {loading ? <Loading /> : this.renderLoadedContent()}
        </Layout>
      </AppWithAuthentication>
    );
  }
}

export default ClaimJobAndStudioSignUp;
