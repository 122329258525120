import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    margin: 0,
    "& + $paragraph": {
      marginTop: theme.spacing(),
    },
  },
}));

function Longform({ text, variant, ...otherProps }) {
  const classes = useStyles();

  if (!text) return null;

  return (
    <Typography component="div" {...otherProps} variant={variant || "body2"}>
      {text.split("\\n").map((paragraph, i) => (
        <p className={classes.paragraph} key={i}>
          {" "}{paragraph}
        </p>
      ))}
    </Typography>
  );
}

export default Longform;
