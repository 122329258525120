import React from "react";
import { Typography } from "@material-ui/core";

import {
  Card,
  Button,
  Strong,
} from "~/src/components/global";

function Longform({
  title, body, button, handleOpenDialog, ...otherProps
}) {
  const { label, url } = button;

  const buttonMarkup = url ? <Button variant="text" color="primary" href={url} target="_blank" rel="noopener noreferrer">{label}</Button>
    : <Button variant="text" color="primary" onClick={handleOpenDialog}>{label}</Button>;

  return (
    <Card elevation={0}>
      <Typography variant="h3" gutterBottom {...otherProps}>
        <Strong>{title}</Strong>
      </Typography>
      <Typography variant="body1" gutterBottom {...otherProps}>{body}</Typography>
      {buttonMarkup}
    </Card>
  );
}

export default Longform;
