import variables from "./variables";

const buttons = {
  MuiButton: {
    root: {
      fontWeight: variables.typography.fontWeightBold,
      lineHeight: variables.typography.body2.lineHeight,
      padding: [[variables.spacing, variables.spacing * 2]],
      whiteSpace: "nowrap",
      transition: undefined,
      "&:hover": {
        backgroundColor: undefined,
      },
      "&$disabled": {
        color: undefined,
        opacity: 0.5,
      },
    },
    text: {
      color: variables.palette.text.secondary,
      padding: [[2, 8]],
      margin: [[-2, -8]],
      "&:hover": {
        color: undefined,
      },
    },
    textPrimary: {
      textDecoration: "underline",
      "&:hover": {
        color: undefined,
        backgroundColor: undefined,
        textDecoration: "underline",
      },
    },
    contained: {
      boxShadow: "0 0 0 1px rgba(0,0,0,0.2)",
      backgroundColor: "white",
      height: variables.shape.actionHeight,
      "&:hover": {
        backgroundColor: undefined,
        boxShadow: undefined,
        "@media (hover: none)": {
          boxShadow: undefined,
        },
      },
      "&:active": {
        boxShadow: undefined,
      },
      "&$disabled": {
        color: undefined,
        boxShadow: undefined,
        backgroundColor: undefined,
      },
    },
    containedPrimary: {
      backgroundColor: variables.palette.primary.main,
      "&:hover": {
        backgroundColor: undefined,
        "@media (hover: none)": {
          backgroundColor: undefined,
        },
      },
    },
  },
  MuiIconButton: {
    root: {
      color: variables.palette.text.primary,
      padding: variables.spacing,
    },
    edgeEnd: {
      marginRight: -1 * variables.spacing,
    },
  },
  MuiButtonGroup: {
    contained: {
      boxShadow: "none",
      "&:hover, &:active": {
        boxShadow: "none",
      },
    },
  },
  MuiFab: {
    root: {
      width: variables.shape.actionHeight,
      height: variables.shape.actionHeight,

    },
  },
};

export default buttons;
